import * as React from 'react'
import PropTypes from 'prop-types'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options'
import { stripEmptyTags } from '../helpers/helpers'

const TextItem = ({ data, counter, total }) => {
  console.log(data.mainContent)
  const title = data && data.title ? data.title : ''
  const main =
    data && data.mainContent ? stripEmptyTags(data.mainContent) : false
  const additional =
    data && data.additionalContent ? data.additionalContent : false
  const titleClass = counter === 0 ? 'h2' : 'h4'
  return (
    <div
      className={`grid-x text-list__row ${
        total > 1 && counter === 0 ? 'text-list__row--first-of-many' : ''
      }`}
    >
      <div className="cell large-shrink">
        {title !== 'News' && (
          <div className="text-list__left">
            <h2 className={titleClass}>{title}</h2>
            <div className="text-list__sub">
              {additional && renderRichText(additional, RichTextOptions)}
            </div>
          </div>
        )}
      </div>
      <div className="cell large-auto">
        <div className="text-list__right">
          {main && renderRichText(main, RichTextOptions)}
        </div>
      </div>
    </div>
  )
}

TextItem.propTypes = {
  member: PropTypes.object,
  counter: PropTypes.number,
  total: PropTypes.number,
}

export default TextItem
