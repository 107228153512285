import * as React from 'react'
import PropTypes from 'prop-types'
import TextItem from '../components/text-item'

const TextList = ({ data }) => {
  const list = data && data.list ? data.list : false //text-list--small
  const sectionClass =
    'content-block text-list ' +
    (data && data.textSize && data.textSize === 'small'
      ? 'text-list--small'
      : '')
  return (
    <section className={sectionClass}>
      <div className="grid-container">
        {list &&
          list.map((text, i) => {
            return (
              <TextItem
                data={text}
                key={text.id}
                counter={i}
                total={list.length}
              />
            )
          })}
      </div>
    </section>
  )
}
TextList.propTypes = {
  data: PropTypes.object,
}
TextList.defaultProps = {
  data: {},
}
export default TextList
