import * as React from 'react'
import PropTypes from 'prop-types'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { RichTextOptions } from '../helpers/rich-text-options.js'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { stripEmptyTags } from '../helpers/helpers'
import LinkItem from '../components/link-item.js'

const Banner = ({ data }) => {
  const colorScheme =
    data && data.backgroundColorScheme ? data.backgroundColorScheme : 'light' // light or dark
  const bannerHeight = data && data.bannerType ? data.bannerType : 'regular' // low, high or regular
  const backgroundType =
    data && data.backgroundType ? data.backgroundType : 'image'
  const image =
    data.image &&
    data.image.gatsbyImageData.images.sources[0].srcSet.childImageSharp
      .gatsbyImageData
  const title =
    data && data.bannerTitle && data.bannerTitle.raw ? data.bannerTitle : ''
  const link = data && data.link ? data.link : false
  const sectionClass = `hero-banner hero-banner--${colorScheme} hero-banner--${bannerHeight.replace(
    ' ',
    ''
  )} rel-wrap`

  return (
    <section className={sectionClass}>
      <div className="grid-container rel-content">
        <div className="grid-x grid-margin-x hero-banner__row">
          <div
            className={`cell ${
              bannerHeight === 'high' ? 'large-9' : 'large-8'
            } rel-content`}
          >
            <div className="hero-banner__content">
              {renderRichText(stripEmptyTags(title), RichTextOptions)}
            </div>
            {link && <LinkItem link={link} cssClass="button" />}
          </div>
          {backgroundType !== 'image' && image && (
            <div className="cell large-6 hero-banner__img-box">
              <GatsbyImage
                image={image}
                alt=""
                className="hero-banner__image"
              />
            </div>
          )}
        </div>
      </div>
      {backgroundType === 'image' && image && (
        <GatsbyImage
          image={image}
          alt=""
          className="stretched-img hero-banner__bg"
        />
      )}
    </section>
  )
}
Banner.propTypes = {
  data: PropTypes.object,
}
Banner.defaultProps = {
  data: [],
}
export default Banner
